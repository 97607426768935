import { Component, OnDestroy, OnInit } from '@angular/core';
declare function setApiUrlsForEnrollment(a: any): any;
declare function getChatbotIdForEnrollmentBot(): any;
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})
export class ChatbotComponent implements OnInit, OnDestroy {

  isChecked:boolean = false;
  welcomeAcknowledgePage = true;
  acknowledgeDocument = false;
  userAcknowledgementStatus = false;
  public isVisible: boolean = false;
  public showDiv: boolean = true;
  public isChatbotOpen: boolean = false;
  public isMaximizeFromSalesforceUser: boolean = false;
  public isMaximized: boolean = false;
  selectedCategory: string = 'Walden General';
  public dataFromSalesforce: any = {};
  private messageHandler: (event: MessageEvent) => void;

  constructor() { }

  ngOnInit() {
    setApiUrlsForEnrollment(environment.enrollmentConfig);
    this.toggleVisibility();
    getChatbotIdForEnrollmentBot();
    this.checkAcknowledge();
    this.checkMsgFromSalesforce();
  }

  checkMsgFromSalesforce() {
    if (!this.messageHandler) {
      this.messageHandler = this.receiveMessageFromSalesforce.bind(this);    
      window.addEventListener('message', this.messageHandler.bind(this), false);
    }
  }

  checkAcknowledge() {
    var getResult = localStorage.getItem('salesforceuserack');
    if(getResult) {
      this.userAcknowledgementStatus = true;
      this.welcomeAcknowledgePage = false;
    } else {
      this.userAcknowledgementStatus = false;
    }
  }

  acknowledgeRead(){
    this.welcomeAcknowledgePage = false;
    this.acknowledgeDocument = true;
  }

  userAcknowledged(){
   // console.log('is checked: ', isChecked);
    this.acknowledgeDocument = false;
    this.userAcknowledgementStatus = true;
    localStorage.setItem('salesforceuserack', this.userAcknowledgementStatus.toString());
  }

  fadeInFadeOut() {
    this.showDiv = !this.showDiv;
  }

  maximizeChat() {
    // const chatPopup = document.querySelector('.hk-chatbot-popup');
    // if (chatPopup.classList.contains('maximized')) {
    //   chatPopup.classList.remove('maximized');
    // } else {
    //   chatPopup.classList.add('maximized');
    // }
   this.isMaximizeFromSalesforceUser = !this.isMaximizeFromSalesforceUser;
  // this.isMaximized = !this.isMaximized;
    if (this.isMaximizeFromSalesforceUser) {
      this.sendMsgToSalesforceForMaximize();
    } else {
      this.sendMsgSalesforceForGoBackToOriginal();
    }
  }

  public toggleVisibility() {
    console.log('toddle function');
    this.isVisible = !this.isVisible;
    //this.sendMessageToSalesforceForMinimizeChatbot();
}

public FAQs = [
  {category:"Walden General",query:"Does Walden offer job placement?"},
  {category:"Walden General",query:"Does Walden offer tutoring?"},
  {category:"Walden General",query:"Why should I choose Walden?"},
  {category:"Walden General",query:"What does fafsa stand for?"},
  {category:"Walden General",query:"What does degree acceleration mean?"},
  {category:"Walden General",query:"Where is Walden located?"},
  {category:"Nursing",query:"What is BSN Aim?"},
  {category:"Nursing",query:"What is the GPA needed to enter into the bsn?"},
  {category:"Nursing",query:"What are the concentrations for the bs in health studies?"},
  {category:"Doctorate",query:"Is the PHD in management program accredited?"},
  {category:"Doctorate",query:"What kind of jobs can someone with a phd in psychology get?"},
  {category:"Doctorate",query:"What is difference between PHD & Doctorate?"},
]

public showChatFAQs=[
  {category:"Walden General",query:"Does Walden offer job placement?"},
  {category:"Walden General",query:"Does Walden offer tutoring?"},
  {category:"Walden General",query:"Why should I choose Walden?"},
  {category:"Walden General",query:"What does fafsa stand for?"},
  {category:"Walden General",query:"What does degree acceleration mean?"},
  {category:"Walden General",query:"Where is Walden located?"},
  {category:"Nursing",query:"What is BSN Aim?"},
  {category:"Nursing",query:"What is the GPA needed to enter into the bsn?"},
  {category:"Nursing",query:"What are the concentrations for the bs in health studies?"},
  {category:"Doctorate",query:"Is the PHD in management program accredited?"},
  {category:"Doctorate",query:"What kind of jobs can someone with a phd in psychology get?"},
  {category:"Doctorate",query:"What is difference between PHD & Doctorate?"},
];

// applyFAQsSelection(event: Event, string: String){
//   if (string == '') {
//     // var applyFAQ = document.getElementById("applyFaq");
//     // applyFAQ.style.backgroundColor = "#C4C4C4";
//     // var selectElement = document.getElementById("categorySelect") as HTMLSelectElement;
//     // var selectedOption = selectElement.options[selectElement.selectedIndex].value;
//     // console.log("Selected options : ",selectedOption);
//     const selectElement = event.target as HTMLSelectElement;
//       const selectedOption = selectElement.value;
//       console.log("Selected options : ", selectedOption);
//     if(this.FAQs.length>0){
//       this.showChatFAQs=[];
//       this.FAQs.forEach((FAQ)=>{
//         if(selectedOption===FAQ.category){
//           this.showChatFAQs.push({'query':FAQ.query});
//         }
//       })
//     }
//     console.log("Response list: ",this.showChatFAQs);
//   } else {
//       const selectedOption = this.selectedCategory;
//       console.log("Selected options : ", selectedOption);
//     if(this.FAQs.length>0){
//       this.showChatFAQs=[];
//       this.FAQs.forEach((FAQ)=>{
//         if(selectedOption===FAQ.category){
//           this.showChatFAQs.push({'query':FAQ.query});
//         }
//       })
//     }
//     console.log("Response list: ",this.showChatFAQs);
//   }
// }



sendMessageToSalesforceForMinimizeChatbot() {
  const message = { function: 'CLOSE_CHATBOT' };
  console.log('enrollmentConfig.salesforceConfig.url: ', environment.enrollmentConfig.salesforceConfigUrl);
  window.parent.postMessage(message, environment.enrollmentConfig.salesforceConfigUrl);
}

receiveMessageFromSalesforce(event: MessageEvent) {
  console.log('event from ES: ', event);
  // if (event.origin !== environment.enrollmentConfig.salesforceConfigUrl) {
  //   return;
  // }
  const data = event.data;
  console.log('Message from origin Angular:', event.origin);
  if (!data || typeof data !== 'object') {
    console.log('Invalid data received:', data);
    return;
  }
  switch (data.function) {
    case 'ORIGINAL_CHATBOT_SIZE':
      this.sendMsgSalesforceForGoBackToOriginal();
      //this.isMaximizeFromSalesforceUser = !this.isMaximizeFromSalesforceUser;
      break;

    case 'ON_LOAD_SALESFORCE_DATA':
      // Ensure user_data and required properties are present
      if (data.user_data && data.user_data.email && data.user_data.last_name && data.user_data.first_name) {
        sessionStorage.setItem('userName', `${data.user_data.last_name}, ${data.user_data.first_name}`);
        sessionStorage.setItem('userEmail', data.user_data.email);
      } else {
        console.log('Incomplete user_data:', data.user_data);
      }
      break;
    default:
      console.log('Unhandled function:', data.function);
  }
}

sendMsgToSalesforceForMaximize() {
  console.log('call the maximize');
  this.isMaximized = !this.isMaximized;
  const message = { function: 'MAXIMIZE_CHATBOT_SIZE' };
  var getChatbotBody = document.getElementById('chatbot-body');
  getChatbotBody.style.height = 78 + 'vh';
 // console.log('enrollmentConfig.salesforceConfig.url: ', environment.enrollmentConfig.salesforceConfigUrl);
  window.parent.postMessage(message, environment.enrollmentConfig.salesforceConfigUrl);
}

sendMsgSalesforceForGoBackToOriginal() {
  console.log('call the original size');
  this.isMaximized = !this.isMaximized;
  this.isMaximizeFromSalesforceUser = false;
  var getChatbotBody = document.getElementById('chatbot-body');
  getChatbotBody.style.height = 80 + 'vh';
  const message = { function: 'ORIGINAL_CHATBOT_SIZE'};
  window.parent.postMessage(message, environment.enrollmentConfig.salesforceConfigUrl);
}

ngOnDestroy(): void {
  if (this.messageHandler) {
    window.removeEventListener('message', this.messageHandler, false);
    this.messageHandler = null;
}
}

}
